import { SettingFilled, DashboardOutlined } from "@ant-design/icons"
import {
  APP_PREFIX_PATH,
  // AUTH_PREFIX_PATH
} from "configs/AppConfig"
import { MdFeedback } from "react-icons/md"
import {
  FaBullhorn,
  FaClipboard,
  FaCoins,
  FaGifts,
  // FaSignOutAlt,
  FaUserPlus,
  FaUsers,
} from "react-icons/fa"
// import Utils from "utils"

//key now corresponding to the role name in SSO
const setupSubMenu = [
  {
    key: "Member Card Group",
    path: `${APP_PREFIX_PATH}/setup/card-group`,
    title: "sidenav.main-menu.setup.member-card-group",
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "Member Card Category",
    path: `${APP_PREFIX_PATH}/setup/card-category`,
    title: "sidenav.main-menu.setup.member-card-category",
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "Task Group",
    path: `${APP_PREFIX_PATH}/setup/task-group`,
    title: "sidenav.main-menu.setup.task-group",
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "Activity Type",
    path: `${APP_PREFIX_PATH}/setup/activity-type`,
    title: "sidenave.main-menu.setup.activity-type",
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "Product Group",
    path: `${APP_PREFIX_PATH}/setup/product-group`,
    title: "sidenave.main-menu.setup.product-group",
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "Voucher Listing",
    path: `${APP_PREFIX_PATH}/setup/voucher-listing`,
    title: "sidenave.main-menu.setup.voucher-listing",
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "Custom Field",
    path: `${APP_PREFIX_PATH}/setup/custom-field`,
    title: "sidenave.main-menu.setup.custom-field",
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "Lead Source",
    path: `${APP_PREFIX_PATH}/setup/lead-source`,
    title: "sidenave.main-menu.setup.lead-source",
    breadcrumb: true,
    submenu: [],
  },
]

const registrationSubMenu = [
  {
    key: "Customer",
    path: `${APP_PREFIX_PATH}/registration/customers`,
    title: "sidenav.main-menu.registration.customer",
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "Self-Service URL Generator",
    path: `${APP_PREFIX_PATH}/registration/self-service`,
    title: "sidenav.main-menu.registration.self-service",
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "Import Leads",
    path: `${APP_PREFIX_PATH}/registration/import-leads`,
    title: "sidenav.main-menu.registration.import-leads",
    breadcrumb: true,
    submenu: [],
  },
]
const pointsManagement = [
  {
    key: "Point Structure",
    path: `${APP_PREFIX_PATH}/points-management/point-structure`,
    title: "sidenav.main-menu.points-management.point-structure",
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "Point Rate",
    path: `${APP_PREFIX_PATH}/points-management/point-rate`,
    title: "sidenav.main-menu.points-management.point-rate",
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "Point Adjustment",
    path: `${APP_PREFIX_PATH}/points-management/points-adjustment`,
    title: "sidenav.main-menu.points-management.point-adjustment",
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "Point Expiry",
    path: `${APP_PREFIX_PATH}/points-management/points-expiry`,
    title: "sidenav.main-menu.points-management.point-expiry",
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "Customer Transaction",
    path: `${APP_PREFIX_PATH}/points-management/customer-transactions/listing`,
    title: "sidenav.main-menu.points-management.customer-transaction",
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "Point Expiry Rewards",
    path: `${APP_PREFIX_PATH}/points-management/point-expiry-rewards`,
    title: "sidenav.main-menu.points-management.point-expiry-rewards",
    breadcrumb: true,
    submenu: [],
  },
]

const memberSubMenu = [
  {
    key: "Card Category Approval",
    path: `${APP_PREFIX_PATH}/member/level-histories/approval`,
    title: "sidenav.main-menu.member.card-category-approval",
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "Archive In-active Member",
    path: `${APP_PREFIX_PATH}/member/archive`,
    title: "sidenav.main-menu.member.archive.inactive.member",
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "Referrer Listing",
    path: `${APP_PREFIX_PATH}/member/referrer-listing`,
    title: "sidenav.main-menu.member.referrer.listing",
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "Member Profile Amendment Review",
    path: `${APP_PREFIX_PATH}/member/profile-amendment-review`,
    title: "sidenav.main-menu.member.member-profile-amendment-review",
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "Update Member Listing",
    path: `${APP_PREFIX_PATH}/member/update-member-listing`,
    title: "sidenav.main-menu.member.update-member-listing",
    breadcrumb: true,
    submenu: [],
  },
]

const rewardsSubMenu = [
  {
    key: "Rewards",
    path: `${APP_PREFIX_PATH}/rewards/rewards`,
    title: "sidenav.main-menu.rewards",
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "Contact Template",
    path: `${APP_PREFIX_PATH}/rewards/contact-templates`,
    title: "sidenav.main-menu.contact-templates",
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "Claim Rewards",
    path: `${APP_PREFIX_PATH}/rewards/claim-rewards`,
    title: "sidenav.main-menu.claim-rewards",
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "Redeem Rewards",
    path: `${APP_PREFIX_PATH}/rewards/redeem-rewards`,
    title: "sidenav.main-menu.redeem-rewards",
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "Cancel Redeem Rewards",
    path: `${APP_PREFIX_PATH}/rewards/cancel-redeem-rewards`,
    title: "sidenav.main-menu.cancel-redeem-rewards",
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "Collect Rewards",
    path: `${APP_PREFIX_PATH}/rewards/collect-rewards`,
    title: "sidenav.main-menu.collect-rewards",
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "Cancel Collect Rewards",
    path: `${APP_PREFIX_PATH}/rewards/cancel-collect-rewards`,
    title: "sidenav.main-menu.cancel-collect-rewards",
    breadcrumb: true,
    submenu: [],
  },
]

const campaignSubMenu = [
  {
    key: "Campaign",
    path: `${APP_PREFIX_PATH}/campaign/campaigns/listing`,
    title: "sidenav.main-menu.campaign.campaigns",
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "Campaign Target List",
    path: `${APP_PREFIX_PATH}/campaign/campaign-target-list`,
    title: "sidenav.main-menu.campaign.campaign-target-list",
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "Contact Template",
    path: `${APP_PREFIX_PATH}/campaign/contact-templates`,
    title: "sidenav.main-menu.campaign.contact-templates",
    breadcrumb: true,
    submenu: [],
  },
]

const reportingSubMenu = [
  {
    key: "Member Listing",
    path: `${APP_PREFIX_PATH}/reporting/member-listing`,
    title: "sidenav.main-menu.reporting.member.listing",
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "Member Profile Amendment Report",
    path: `${APP_PREFIX_PATH}/reporting/member-profile-amendment-report`,
    title: "sidenav.main-menu.reporting.member-profile-amendment-report",
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "Member Point Statement",
    path: `${APP_PREFIX_PATH}/reporting/member-point-statement`,
    title: "sidenav.main-menu.reporting.member.point.statement",
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "Customer Analysis Report",
    path: `${APP_PREFIX_PATH}/reporting/customer-analysis-report`,
    title: "sidenav.main-menu.reporting.customer-analysis-report",
    breadcrumb: true,
  },
  {
    key: "Customer Reward Status Report",
    path: `${APP_PREFIX_PATH}/reporting/customer-reward-status-report`,
    title: "sidenav.main-menu.reporting.customer.reward.status.report",
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "Customer Performance Report",
    path: `${APP_PREFIX_PATH}/reporting/customer-performance-report`,
    title: "sidenav.main-menu.reporting.customer.performance.report",
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "Product Sales Analysis Report",
    path: `${APP_PREFIX_PATH}/reporting/product-sales-analysis-report`,
    title: "sidenav.main-menu.reporting.product-sales-analysis-report",
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "Member Sales Analysis Report",
    path: `${APP_PREFIX_PATH}/reporting/member-sales-analysis-report`,
    title: "sidenav.main-menu.reporting.member-sales-analysis-report",
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "Customer Transaction Report",
    path: `${APP_PREFIX_PATH}/reporting/customer-transaction-report`,
    title: "sidenav.main-menu.reporting.customer-transaction-report",
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "Non-Member Transaction Report",
    path: `${APP_PREFIX_PATH}/reporting/non-member-transaction-report`,
    title: "sidenav.main-menu.reporting.non-member-transaction-report",
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "Repeat Customer Analysis Report",
    path: `${APP_PREFIX_PATH}/reporting/repeat-customer-analysis-report`,
    title: "sidenav.main-menu.reporting.repeat-customer-analysis-report",
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "Point Expiry Report",
    path: `${APP_PREFIX_PATH}/reporting/point-expiry-report`,
    title: "sidenav.main-menu.reporting.point-expiry-report",
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "Premium Gift Redemption Listing",
    path: `${APP_PREFIX_PATH}/reporting/premium-gift-redemption-listing`,
    title: "sidenav.main-menu.reporting.premium-gift-redemption-listing",
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "Inactive/Reactive Member Listing",
    path: `${APP_PREFIX_PATH}/reporting/inactive-reactive-member-listing`,
    title: "sidenav.main-menu.reporting.inactive-reactive-member-listing",
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "Member Category Analysis Report",
    path: `${APP_PREFIX_PATH}/reporting/member-category-analysis-report`,
    title: "sidenav.main-menu.reporting.member-category-analysis-report",
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "Audit Trail Report",
    path: `${APP_PREFIX_PATH}/reporting/audit-trail-report`,
    title: "sidenav.main-menu.reporting.audit-trail-report",
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "Feedback Analysis Report",
    path: `${APP_PREFIX_PATH}/reporting/feedback-analysis-report`,
    title: "sidenav.main-menu.reporting.feedback-analysis-report",
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "Campaign Target List Report",
    path: `${APP_PREFIX_PATH}/reporting/campaign-target-list-report`,
    title: "sidenav.main-menu.reporting.campaign-target-list-report",
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "Friend-Get-Friend Sales Report",
    path: `${APP_PREFIX_PATH}/reporting/friend-get-friend-sales-report`,
    title: "sidenav.main-menu.reporting.friend-get-friend-sales-report",
    breadcrumb: true,
    submenu: [],
  },
]

const feedbackSubMenu = [
  {
    key: "Feedback",
    path: `${APP_PREFIX_PATH}/feedback/feedback`,
    title: "sidenav.main-menu.feedback.feedback",
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "Question",
    path: `${APP_PREFIX_PATH}/feedback/question`,
    title: "sidenav.main-menu.feedback.question",
    breadcrumb: true,
    submenu: [],
  },
]

const mainMenuNavTree = [
  {
    key: "Main Menu",
    path: `${APP_PREFIX_PATH}`,
    title: "sidenav.main-menu",
    breadcrumb: false,
    submenu: [
      {
        key: "Dashboard",
        path: `${APP_PREFIX_PATH}/dashboard`,
        title: "sidenav.main-menu.dashboard",
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "Registration",
        path: `${APP_PREFIX_PATH}/registration`,
        title: "sidenav.main-menu.registration",
        icon: FaUserPlus,
        breadcrumb: false,
        submenu: registrationSubMenu,
      },
      {
        key: "Rewards",
        path: `${APP_PREFIX_PATH}/rewards`,
        title: "sidenav.main-menu.rewards",
        icon: FaGifts,
        breadcrumb: false,
        submenu: rewardsSubMenu,
      },
      {
        key: "Campaign",
        path: `${APP_PREFIX_PATH}/campaign`,
        title: "sidenav.main-menu.campaign",
        icon: FaBullhorn,
        breadcrumb: false,
        submenu: campaignSubMenu,
      },
      {
        key: "Reporting",
        path: `${APP_PREFIX_PATH}/reporting`,
        title: "sidenav.main-menu.reporting",
        icon: FaClipboard,
        breadcrumb: false,
        submenu: reportingSubMenu,
      },
      {
        key: "Member",
        path: `${APP_PREFIX_PATH}/member`,
        title: "sidenav.main-menu.member",
        icon: FaUsers,
        breadcrumb: false,
        submenu: memberSubMenu,
      },
      {
        key: "Points Management",
        path: `${APP_PREFIX_PATH}/points-management`,
        title: "sidenav.main-menu.points-management",
        icon: FaCoins,
        breadcrumb: false,
        submenu: pointsManagement,
      },
      {
        key: "Setup",
        path: `${APP_PREFIX_PATH}/setup`,
        title: "sidenav.main-menu.setup",
        icon: SettingFilled,
        breadcrumb: false,
        submenu: setupSubMenu,
      },
      {
        key: "Feedback",
        path: `${APP_PREFIX_PATH}/feedback`,
        title: "sidenav.main-menu.feedback",
        icon: MdFeedback,
        breadcrumb: false,
        submenu: feedbackSubMenu,
      },
    ],
  },
]

const navigationConfig = [...mainMenuNavTree]

export default navigationConfig
