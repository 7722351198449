import setNotification from "components/shared-components/Notification"
import { isArray } from "lodash"
import moment from "moment"
import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import { createLoginActivity } from "redux/actions/Auth"
import {
  resetClaimRewardAlert,
  resultClaimReward,
} from "redux/actions/ClaimReward"
import { setIsLoading } from "redux/actions/TransactionHistory"
import {
  GET_CLAIM_REWARD,
  SET_CLAIM_REWARD,
  ON_CLAIM_REWARD,
  SET_ON_CLAIM_REWARD,
} from "redux/constants/ClaimReward"
import CommonService from "services/CommonService"
import CustomerRewardService from "services/CustomerRewardService"
import ProntoSyncVoucherService from "services/ProntoSyncVoucherService"

const setError = (message, description) =>
  setNotification({
    type: "error",
    message,
    description,
  })

export function* getClaimRewardAPI() {
  yield takeEvery(GET_CLAIM_REWARD, function* ({ payload }) {
    try {
      yield put(resetClaimRewardAlert())

      const data = yield call(
        CustomerRewardService.getCustomerReward,
        payload.ReferenceCode
      )

      /** Validation */
      const VoucherType = data.data?.Reward?.Type
      const RewardCode = data.data?.Reward?.Code

      if (RewardCode !== payload.RewardCode) {
        setError("Invalid Reward")
        return
      }

      if (
        VoucherType === "Free Gift" ||
        VoucherType === "Premium Gift" ||
        VoucherType === "Contest"
      ) {
        setError("Get ClaimReward Failed", "Customer Reward Not Found.")
        return
      }

      const isMembership = data.data.Customer.CustomerMemberships.some(
        ({ MembershipNo }) => MembershipNo === payload.MemberIdValue
      )
      const isIdType = payload.MemberIdValue === data.data.Customer.IdNo
      const isContactNo = payload.MemberIdValue === data.data.Customer.ContactNo
      const isEmail = payload.MemberIdValue === data.data.Customer.Email

      const isMatched =
        payload.MemberId === "MembershipNo"
          ? isMembership
          : payload.MemberId === "IdNo"
          ? isIdType
          : payload.MemberId === "ContactNo"
          ? isContactNo
          : payload.MemberId === "Email"
          ? isEmail
          : false

      if (!isMatched) {
        setError(
          "Get ClaimReward Failed",
          "Member Details did not match with rewards record"
        )
        return
      }

      if (data.data.Customer.Status !== "Active") {
        setError("Get ClaimReward Failed", "Customer is not Active")
        return
      }

      data.data.MemberId = payload.MemberId
      data.data.MemberIdValue = payload.MemberIdValue
      data.data.ReferenceCode = payload.ReferenceCode
      /** End of Validation */

      data.type = SET_CLAIM_REWARD
      yield put(resultClaimReward(data))
    } catch (err) {
      setError("Get Claim Reward Failed", err.response.data.message ?? "")
    }
  })
}

export function* onClaimRewardAPI() {
  yield takeEvery(ON_CLAIM_REWARD, function* ({ payload }) {
    yield put(setIsLoading(true))

    try {
      const { ref_code, VoucherNo, SecurityCode, PrefixCode, ValidityTo } =
        payload

      const userInfo = JSON.parse(localStorage.getItem("userinfo"))
      const localSystemTime = moment.utc().toISOString()
      const globalTime = yield call(CommonService.getGlobalUTCTime)

      const parseToDateString = (dateValue) => {
        if (dateValue instanceof Date) {
          return moment(dateValue).toISOString()
        }
        if (typeof dateValue === "string") {
          return dateValue
        }
        return null
      }

      const claimedDateTime =
        parseToDateString(globalTime?.data?.dateTime) || localSystemTime

      if (userInfo) {
        const dataClaim = {
          ClaimedById: userInfo.id + "",
          ClaimedOutletCode: userInfo.building.code + "",
          ClaimedDate: claimedDateTime,
          Status: "Claimed",
          VoucherNo,
          SecurityCode,
        }

        /** Get Voucher No */
        if (!dataClaim.VoucherNo || !dataClaim.SecurityCode) {
          const voucher = yield call(
            ProntoSyncVoucherService.getAvailableVoucher,
            {
              voucherType: "G",
              voucherPrefix: PrefixCode,
            }
          )

          if (!dataClaim.VoucherNo) dataClaim.VoucherNo = voucher.data.VoucherNo
          if (!dataClaim.SecurityCode)
            dataClaim.SecurityCode = voucher.data.SecurityCode

          yield call(ProntoSyncVoucherService.updateVoucher, {
            VoucherNo: voucher.data.VoucherNo,
            SecurityCode: voucher.data.SecurityCode,
            data: {
              ExpiryDate: ValidityTo,
              Status: "R",
              IsAssignedYN: "Y",
              PostedToProntoYN: "N",
              VoucherType: voucher.data.VoucherType,
              Value: voucher.data.Value + "",
              Reference: voucher.data.Reference,
            },
          })
        }
        /** End get Voucher No */

        const data = yield call(CustomerRewardService.updateCustomerReward, {
          ref_code,
          data: dataClaim,
        })

        yield put(
          createLoginActivity(`Claim Rewards. ReferenceCode - ${ref_code}`)
        )

        data.data.VoucherNo = dataClaim.VoucherNo
        data.data.SecurityCode = dataClaim.SecurityCode

        data.type = SET_ON_CLAIM_REWARD

        yield put(resultClaimReward(data))
      } else {
        setError("Claim Reward Failed")
      }
    } catch (err) {
      setError(
        "Claim Reward Failed",
        isArray(err.response.data?.message)
          ? err.response.data?.message[0]
          : err.response.data.message ?? ""
      )
    }

    yield put(setIsLoading(false))
  })
}

export default function* rootSaga() {
  yield all([fork(getClaimRewardAPI), fork(onClaimRewardAPI)])
}
