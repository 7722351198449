import fetch from "auth/APIFetchInterceptor"
import moment from "moment"
import { AUTH_TOKEN } from "redux/constants/Auth"

const CustomerAnalysisReportService = {}

CustomerAnalysisReportService.getCustomerAnalysisReport = function (data) {
  const searchQuery = {}

  if (data?.OutletCode && data.OutletCode?.length)
    searchQuery.OutletCode = data.OutletCode
  if (data?.Date) {
    searchQuery.DateFrom = moment(data?.Date[0]).format("YYYY-MM-DD")
    searchQuery.DateTo = moment(data?.Date[1]).format("YYYY-MM-DD")
  }

  if (data?.MemberType) searchQuery.MemberType = data.MemberType
  if (data?.AnalysisType) searchQuery.AnalysisType = data.AnalysisType
  if (data?.ReportOption) searchQuery.ReportOption = data.ReportOption
  if (data?.ReportType) searchQuery.ReportType = data.ReportType

  const payload = { ...searchQuery }

  return fetch({
    url: "/reporting/customer-analysis-report",
    method: "post",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data: payload,
  })
}

export default CustomerAnalysisReportService
