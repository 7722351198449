import fetch from "auth/APIFetchInterceptor"

const CustomerTagService = {}

CustomerTagService.createCustomerTag = function (data) {
  return fetch({
    url: "/customer/tags/add",
    method: "post",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    data: data,
  })
}

CustomerTagService.getListCustomerTag = function (data) {
  const payload = {
    Rows: data.rows,
    Page: data.pages,
    Search: JSON.stringify(data.queries ?? "{}"),
  }

  return fetch({
    url: "/customer/tags/find-all",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: payload,
  })
}

CustomerTagService.getCustomerTag = function (data) {
  return fetch({
    url: "/customer/tags/find-one",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: { TagId: data.id },
  })
}

CustomerTagService.deleteCustomerTag = function (data) {
  return fetch({
    url: `/customer/tags/delete`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: { TagId: data.TagId },
  })
}

export default CustomerTagService
