import axios from "axios"
import { COMMON_API_BASE_URL } from "configs/AppConfig"

const host = COMMON_API_BASE_URL

const CommonService = {}

CommonService.getCommonData = function (data) {
  return axios({
    url: host + "/lists/items",
    method: "get",
    params: {
      ListName: data,
    },
  })
}

CommonService.uploadImageMediasInternal = function (data) {
  return axios({
    url: host + "/medias/internal",
    method: "post",
    data: data,
  })
}

CommonService.getMedias = function ({ rows, page, ObjectId, ObjectType }) {
  return axios({
    url: host + "/medias",
    method: "get",
    params: {
      rows: rows || 100,
      page: page || 1,
      ObjectId,
      ObjectType,
    },
  })
}

CommonService.deleteMedia = function (id) {
  return axios({
    url: host + "/medias/" + id,
    method: "delete",
  })
}

CommonService.createFieldTranslation = function (data) {
  return axios({
    url: host + "/field-translations",
    method: "post",
    data,
  })
}

CommonService.getContactLog = function (data) {
  const searchQuery = {}
  if (!(data.ContactType === "")) {
    searchQuery.ContactType = data.ContactType
  }
  if (!(data.ContactValue === "")) {
    searchQuery.ContactValue = data.ContactValue
  }

  return axios({
    url: host + "/contact-log/find-all",
    method: "post",
    data: {
      Rows: data.rows ? data.rows : 1000,
      Page: data.page ? data.page : 1,
      Search: JSON.stringify(searchQuery ?? {}),
    },
  })
}

CommonService.getPostcode = function (data) {
  const searchQuery = {}
  if (!(data.Country === "")) {
    searchQuery.Country = data.Country
  }
  if (!(data.Postcode === "")) {
    searchQuery.PrefixCode = data.Postcode
  }

  return axios({
    url: host + "/postcodes/find-all",
    method: "post",
    data: {
      Rows: data.rows ? data.rows : 1000,
      Page: data.page ? data.page : 1,
      Search: JSON.stringify(searchQuery ?? {}),
    },
  })
}

CommonService.getGlobalUTCTime = function () {
  return axios({
    url: "https://timeapi.io/api/time/current/zone?timeZone=UTC",
    method: "get",
  })
}

export default CommonService
